import * as React from "react"
import Seo from "../../components/seo"
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Copyright from "../../components/copyright"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { GatsbyImage } from "gatsby-plugin-image"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Faq = ({data}) => {
  const entry = data.prismicFaqPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/faq/" />
      <Header />
      <div className="w-full pt-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex:none sm:flex justify-between gap-12">
            <div className="w-full sm:w-8/12">
              {entry.data.faq.map((entry,i) => (
              <Disclosure as="div" key={`faq_${i}`} className="w-full mb-6 last:mb-0">
                {({ open }) => (
                  <div className="border-2 border-grey p-6">
                    <dt>
                      <Disclosure.Button className="text-left w-full flex justify-between items-start">
                        <h3 className="font-ubuntu text-black text-2xl inline">
                          {entry.heading1.text}</h3>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transition transform inline text-red')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-6 mb-3 pr-12">
                      <div className="font-ubuntu prose text-black prose-xl leading-normal">
                        {RichText.render(entry.text1.richText)}
                      </div>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
              ))}
            </div>
            <div className="w-full sm:w-4/12 mt-12 sm:mt-0">
              <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicFaqPage {
    data {
      page_title
      meta_description
      heading {
        text
      }
      image {
        gatsbyImageData(width: 640, height: 640)
      }
      faq {
        text1 {
          richText
        }
        heading1 {
          text
        }
      }
    }
  }
}
`

export default Faq
